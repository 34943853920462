



































import { FormOfflineCosignTransactionTs } from './FormOfflineCosignTransactionTs';
export default class FormOfflineCosignTransaction extends FormOfflineCosignTransactionTs {}
