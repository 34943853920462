








































































































import OfflineCosignTransactionTs from './OfflineCosignTransactionTs';
export default class OfflineCosignTransaction extends OfflineCosignTransactionTs {}
